import { z } from "zod";

export const browserSettingsSchema = z.object({
  STRIPE_PUBLISHABLE_KEY: z.string().min(1),

  SUPABASE_URL: z.string().url(),
  SUPABASE_KEY_ANON: z.string().min(1),

  SENTRY_DSN: z.string().optional(),
  SENTRY_ENABLED_IN_FRONTEND: z.coerce.boolean().optional(),
});

const serverSettingsSchema = browserSettingsSchema.and(
  z.object({
    STRIPE_SECRET_KEY: z.string().min(1),
    STRIPE_WEBHOOK_SECRET: z.string().min(1),

    SUPABASE_KEY_SERVICE: z.string().min(1),

    ZOOM_CLIENT_ID: z.string().min(1),
    ZOOM_CLIENT_SECRET: z.string().min(1),
    ZOOM_ACCOUNT_ID: z.string().min(1),

    SMTP_HOST: z.string().min(1),
    SMTP_PORT: z.coerce.number().min(1).max(65535),
    SMTP_USER: z.string().min(1),
    SMTP_PASSWORD: z.string().min(1),
    SMTP_SECURE: z.coerce.boolean(),

    ADMIN_USER_IDS: z.preprocess(
      (val) => String(val || "").split(","),
      z.array(z.string()),
    ),
  }),
);

export const browserSettings = browserSettingsSchema.parse(
  (typeof process === "undefined" ? (window as any) : process).env,
);

export const serverSettings = (
  typeof process === "undefined"
    ? null
    : serverSettingsSchema.parse(process.env)
)!;
